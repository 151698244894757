<template>
  <section id="WhereWeAreMobile">
		<div class="mobile-subnav">
			<SideBarNavMobile subsectionTitle="Where We Are" />
		</div>
		<div class="sidenav">
			<b-nav v-b-scrollspy>
				<b-nav-item
          v-for="(navItem, index) in navItems"
          :key="index"
          :href="navItem.link"
          v-scroll-to="navItem.link"
        >
          <div class="icon">
          </div>
        </b-nav-item>
			</b-nav>
		</div>
		<div
			class="whereweare-venue"
			v-for="(venueItem, index) in venueItems"
			:key="index"
		>
			<div class="location-tab" :style="{'background-image': 'url(' + venueItem.venueBg + ')'}" :id="venueItem.id">
				<div class="locations">
					<div class="locations__left">
						<h2 :class="{ singleLineVenue: venueItem.singleLineVenue }">{{ venueItem.venue }}</h2>
						<h2>Allegiant Stadium</h2>
					</div>
					<div class="locations__right" :class="{ singleLineVenue: venueItem.singleLineVenue, threeLineVenue: venueItem.threeLineVenue }">
						<div class="locations__right--pin">
						</div>
						<div class="locations__right--line" :class="{ threeLineVenue: venueItem.threeLineVenue }">
						</div>
						<div class="locations__right--circle" :class="{ threeLineVenue: venueItem.threeLineVenue }">
						</div>
					</div>
				</div>
				<div class="miles">
					<div class="miles__num">
						<h2>{{ venueItem.distance }}</h2>
					</div>
					<div class="miles__word">
						<h3>Miles</h3>
					</div>
				</div>
			</div>
		</div>
  </section>
</template>

<script>
import SideBarNavMobile from '@/components/Nav/SideBarNavMobile'

export default {
	name: "WhereWeAreMobile",
	components: {
		SideBarNavMobile
	},
	data() {
    return {
			navItems: [
				{
					link: '#Aria'
				},
				{
					link: '#Bellagio'
				},
				{
					link: '#CaesarsPalace'
				},
				{
					link: '#EncoreWynn'
				},
				{
					link: '#LVCC'
				},
				{
					link: '#Luxor'
				},
				{
					link: '#MResort'
				},
				{
					link: '#MandalayBay'
				},
				{
					link: '#MGM'
				},
				{
					link: '#NewYork'
				},
				{
					link: '#ParkMGM'
				},
				{
					link: '#ResortsWorld'
				},
				{
					link: '#Stratosphere'
				},
				{
					link: '#Venetian'
				},
			],
			venueItems: [
				{
					id: 'Aria',
					venueBg:  require('@/assets/maps/aria.png'),
					venue: 'Aria',
					distance: '1.7',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'Bellagio',
					venueBg:  require('@/assets/maps/bellagio.png'),
					venue: 'Bellagio',
					distance: '2.0',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'CaesarsPalace',
					venueBg:  require('@/assets/maps/caesars.png'),
					venue: 'Caesars Palace',
					distance: '2.7',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'EncoreWynn',
					venueBg:  require('@/assets/maps/wynn.png'),
					venue: 'Encore & Wynn',
					distance: '3.5',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'LVCC',
					venueBg:  require('@/assets/maps/lvcc.png'),
					venue: 'Las Vegas Convention Center',
					distance: '6.0',
					singleLineVenue: false,
					threeLineVenue: true
				},
				{
					id: 'Luxor',
					venueBg:  require('@/assets/maps/luxor.png'),
					venue: 'Luxor',
					distance: '0.7',
					singleLineVenue: true,
					threeLineVenue: false
        },
				{
					id: 'MResort',
					venueBg:  require('@/assets/maps/m.png'),
					venue: 'M Resort Spa Casino',
					distance: '10.4',
					singleLineVenue: false,
					threeLineVenue: false
				},
				{	
					id: 'MandalayBay',
					venueBg:  require('@/assets/maps/mandalay.png'),
					venue: 'Mandalay Bay',
					distance: '0.7',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'MGM',
					venueBg:  require('@/assets/maps/mgm.png'),
					venue: 'MGM',
					distance: '1.5',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'NewYork',
					venueBg:  require('@/assets/maps/nyny.png'),
					venue: 'New York, New York',
					distance: '1.2',
					singleLineVenue: false,
					threeLineVenue: false
				},
				{
					id: 'ParkMGM',
					venueBg:  require('@/assets/maps/park.png'),
					venue: 'Park MGM',
					distance: '1.5',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'ResortsWorld',
					venueBg:  require('@/assets/maps/resorts-world.png'),
					venue: 'Resorts World Las Vegas',
					distance: '3.5',
					singleLineVenue: false,
					threeLineVenue: false
				},
				{
					id: 'Stratosphere',
					venueBg:  require('@/assets/maps/strat.png'),
					venue: 'Stratosphere',
					distance: '5.1',
					singleLineVenue: true,
					threeLineVenue: false
				},
				{
					id: 'Venetian',
					venueBg:  require('@/assets/maps/venetian.png'),
					venue: 'Venetian',
					distance: '3.7',
					singleLineVenue: true,
					threeLineVenue: false
        }
			]
		}
	},
	mounted() {
		// var scrollbtn = document.querySelector('.whereweare-venue');
    // scrollbtn.addEventListener('click', () => window.scrollBy({
    //   top: document.body.scrollHeight / 13,
    //   behavior: 'smooth'
		// }))

		// scrollbtn.addEventListener('touchend', () => window.scrollBy({
    //   top: document.body.scrollHeight / 13,
    //   behavior: 'smooth'
		// }))

		// function initMobileScroll(ele) {
		// 	var mobileScrollArea = document.getElementById(ele);

		// 	mobileScrollArea.addEventListener('touchstart', function(event){
		// 		touchstart (event);
		// 	});

		// 	mobileScrollArea.addEventListener('touchmove', function(event){
		// 		touchmove (event);
		// 	});

		// 	// let’s set the starting point when someone first touches
		// 	function touchstart(e) {
		// 		startY = e.touches[0].pageY;
		// 		startX = e.touches[0].pageX;
		// 	}

		// 	// calls repeatedly while the user’s finger is moving
		// 	function touchmove(e) {
		// 		var touches = e.touches[0];

		// 		// override the touch event’s normal functionality
		// 		e.preventDefault();

		// 		// y-axis
		// 		var touchMovedY = startY - touches.pageY;
		// 		startY = touches.pageY; // reset startY for the next call
		// 		mobileScrollArea.scrollTop = mobileScrollArea.scrollTop + touchMovedY;

		// 		// x-axis
		// 		var touchMovedX = startX - touches.pageX;
		// 		startX = touches.pageX; // reset startX for the next call
		// 		mobileScrollArea.scrollLeft = mobileScrollArea.scrollLeft + touchMovedX;

		// 		window.console.log('moved');
		// 	}  
		// }
	}
}
</script>

<style lang="scss" scoped>
#WhereWeAreMobile {
	margin-top: 135px;
	height: auto;
	.sidenav {
		position: fixed;
		left: 0;
		top: 30%;
		width: 50px;
		z-index: 1;
		.icon {
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background-color: #858585;
			box-shadow: 0 0 4px 2px rgba(255,255,255,0.50);
			cursor: pointer;
			transition: all 0.25s ease-in-out;
		}
		.nav-link.active {
			.icon {
				background-color: #ffffff;
			}
		}
	}
	.whereweare-venue {
		position: relative;
		height: auto;
		.location-tab {
			position: relative;
      top: 0;
      right: 0;
      width: 100%;
			height: 90vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top left;
      animation: animateRight 1s ease-in-out 0s 1;
			animation-fill-mode: forwards;
      .locations {
				position: absolute;
        bottom: 200px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        &__left {
          text-align: right;
          width: 225px;
          margin-right: 80px;
					h2 {
            font-family: 'ArtegraSans-Bold';
            text-transform: uppercase;
            text-align: right;
            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 25px;
            &.singleLineVenue {
              position: relative;
              top: -10px;
            }
            &:first-child {
              margin-bottom: 75px;
            }
          }
				}
        &__right {
          width: 20%;
          position: absolute;
          right: 0;
          top: 0;
          &.singleLineVenue {
            top: -25px;
          }
          &.threeLineVenue {
            top: 10px;
          }
          &--pin {
            position: absolute;
            top: 14px;
            right: 40px;
            margin-left: 115px;
            background-color: #fff;
            border-radius: 50%;
            border: 10px solid #fff;
            width: 10px;
            height: 10px;
            box-shadow: 0 0 5px 2px #fff;
            &::after {
              position: absolute;
              content: '';
              width: 0px;
              height: 0px;
              bottom: -29px;
              left: -10px;
              border: 10px solid transparent;
              border-top: 17px solid #fff;
            }
          }
          &--line {
            position: absolute;
            display: block;
            top: 57px;
            right: 49px;
            width: 2px;
            height: 74px;
            background-color: #fff;
            box-shadow: 0 0 5px 2px #fff;
            &.threeLineVenue {
              height: 88px;
            }
          }
          &--circle {
            position: absolute;
            display: block;
            top: 145px;
            right: 45px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0 0 5px 2px rgba(255,255,255,0.50);
            &.threeLineVenue {
              top: 160px;
            }
          }
        }
      }
      .miles {
        position: absolute;
        bottom: 65px;
        right: 0;
        display: flex;
        &__num {
          background-image: linear-gradient(180deg, #E2E2E2 0%, #C0C0C0 100%);
          height: 99px;
          width: 150px;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          display: flex;
          justify-content: center;
          transform: skew(-25deg, 0deg);
          z-index: 1;
          margin-right: -25px;
          h2 {
            font-family: 'ArtegraSans-Light';
            font-size: 46px;
            color: #000000;
            letter-spacing: -0.65px;
            text-align: center;
            padding: 20px 0 0 0;
            transform: skew(25deg, 0deg);
          }
        }
        &__word {
          background-image: linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%);
          height: 99px;
          width: 120px;
          z-index: 1;
          h3 {
            font-family: 'ArtegraSans-Bold';
            text-transform: uppercase;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            line-height: 23px;
            padding: 37px 0 0 25px;
          }
        }
      }
    }
	}
}
</style>